jQuery(function($){

	var windowscreen = $( window ).width();

	// slider
	$('.v4-single-slider').slick({
		dots : true
	});

	var sliderEnabled = false;

	function responsiveSlider(breakpoint){
		if(windowscreen <= breakpoint) {
			if(sliderEnabled == false) {
				$('.slider-mobile').slick({
			        slidesToShow: 1,
			        slidesToScroll: 1,
			        infinite: true,
			        dots: true,
			        arrows: false
				});
				$('.v4-moving-house-steps').slick({
			        slidesToShow: 1,
			        slidesToScroll: 1,
			        infinite: true,
			        dots: false,
			        arrows: true
				});
				sliderEnabled = true
			}
		} else {
			if(sliderEnabled == true) {
				$('.slider-mobile, .v4-moving-house-steps').slick('unslick');
				sliderEnabled = false
			}
		}
	}

	responsiveSlider(767);

	$( window ).resize(function() {
		windowscreen = $( window ).width();
		responsiveSlider(767);
	});


	// smooth scroll
	$("body").on("click", ".v4-scroll", function(t) {
	    if (/#/.test(this.href)) {
	      t.preventDefault();
	      var n = $(this).attr("href");
	      var $target = $(n);
	      $("html, body").animate({
	        scrollTop: $target.offset().top
	      }, 600, "swing", function() {
	        window.location.hash = n
	      })
		}
	});

	// smooth scroll

	$('.page-scroll a').click(function() {
	    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') 
	        || location.hostname == this.hostname) {

	        var target = $(this.hash);
	        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
	           if (target.length) {
	             $('html,body').animate({
	                 scrollTop: target.offset().top
	            }, 1000);
	            return false;
	        }
	    }
	});


	// menu
	$('.v4-toggle-nav').click(function(e){
		e.preventDefault();
		$(this).toggleClass('expand');
		$('.v4-main-nav').toggleClass('expand');
	});

	// submenu
	var formfactor = $.restive.getFormFactor();

	$('.v4-main-nav > li').hover(function(e){
		e.preventDefault();
		if(!$('body').hasClass('mobileDevice')) {
			if($(this).children('.v4-submenu-wrapper').length > 0) {
				toggleSubmenu($(this));
			}
		}
	},function(e){
		$(this).removeClass('toggleSubmenu')
	});

	function toggleSubmenu(element) {
		element.removeClass('toggleSubmenu');
		element.addClass('toggleSubmenu');
	}

	$('.mobileDevice .v4-main-nav > li').click(function(e){
		if($(this).children('.v4-submenu-wrapper').length > 0) {
			$(this).toggleClass('toggleSubmenu')
		}
	});

	// tooltips
	$('[data-toggle="tooltip"]').tooltip();

	// validations
	$.extend($.validity.patterns, {
		phone: /^0(2|3|4|7|8){1}[0-9]{8}$/,
		usd: /^\$?((\d{1,3}(,\d{3})*)|\d+)(\.(\d{2})?)?$/,
		address: /^(.+),[\s]*[a-zA-Z]{2,3},[\s]*(\d{4})$/,
		zip:/^\d{4}$/,
		email: /^([\w\d\-\.]+)@{1}(([\w\d\-]{1,67})|([\w\d\-]+\.[\w\d\-]{1,67}))\.(([a-zA-Z\d]{2,4})(\.[a-zA-Z\d]{2})?)$/i
	});
	$.extend($.validity.messages, {
		phone:"Please enter a valid number",
		email:"Please enter a valid email",
		usd: "This field must be formatted as $ dollar amount",
		address: "Please enter correct address format (e.g: Campbell, ACT, 2612)",
		zip: "Please enter correct postcode"
  });

	$('input[type="tel"]').keydown(function(e) {
    if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 224, 17, 91, 93]) !== -1 ||
         // Allow: Ctrl+A
        (e.keyCode == 65 && e.ctrlKey === true) ||
        (e.keyCode == 65 && e.metaKey === true) ||
        (e.keyCode >= 35 && e.keyCode <= 39)) {     // Allow: home, end, left, right
             // let it happen, don't do anything
             return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
    }
  });

	$('[data-validate="phone"]').keypress(function(e){
		txtVal = $(this).val();
		if(txtVal.length > 9) {
			return false;
		} else {
			return true;
		}
	});

	function validateMyAjaxInputs(step) {

    $.validity.start();

    step.find('[required]').each(function(i, value){
      var validationformat = $(this).attr('data-validate');
      var validationmessage = validationMessage($(this));
      if(typeof validationformat !== 'undefined') {
      	if(validationformat !== 'fullname' && validationformat !== 'checkbox') {
      		$(this).require(validationmessage).match(validationformat);
      	} else {
      		$(this).require(validationmessage);
      	}
      } else {
        $(this).require(validationmessage);
      }
    });


    var result = $.validity.end();

    if(typeof localStorage.has_selected_address !== 'undefined' && localStorage.has_selected_address !== '1'){
            if($('.tooltip-inner').length > 0){
                $('.tooltip-inner').append('<br />Please select your address from the list.');
            }else{
                var a = $('#postcode').offset();
                a.top = a.top + 55;
                var tp = $('<div class="tooltip bottom in validity-tooltip" style="top: 463px; left: 487.5px;"><div class="tooltip-arrow"></div><div class="tooltip-inner ">Please select your address from the list.</div></div>').mouseover(function() {
                        $('#postcode').focus(), $(this).fadeOut()
                }).css(a).hide().appendTo("body").fadeIn();
            }

            result.valid = false;
            return false;
    }

    if(step.find('[data-validate="fullname"]').length > 0) {
      firstname="", lastname="";
      if($('[data-validate="fullname"]').val() != '') {
        var fullname_test = $('[data-validate="fullname"]').val().trim().split(' ');
        var a = $('[data-validate="fullname"]').offset();
         a.left += 0, a.top += $('[data-validate="fullname"]').height();
        if(fullname_test.length < 2) {
          var tp = $('<div class="tooltip bottom in validity-tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner ">Please enter your first name and last name</div></div>').mouseover(function() {
                    $('[data-validate="fullname"]').focus(), $(this).fadeOut() }).css(a).hide().appendTo("body").fadeIn();
          result.valid = false;
          return false;
        } else {
          lastname = fullname_test[fullname_test.length-1];
          for (var i = 0; i < fullname_test.length - 1; i++) {
             if (i > 0) {
                firstname += ' ';
             }
             firstname += fullname_test[i];
          }
          if(!result.valid == false) {
            result.valid = true;
          }
        }
      }
    }

    if(step.find('[data-validate="checkbox"]').length > 0) {
      step.find('[data-validate="checkbox"]').each(function(){
        var validationmessage = validationMessage($(this))
        var a = $(this).parents('label').offset();
         a.left += 0, a.top += $(this).parents('label').height();
         if(!$(this).is(':checked')) {
          result.valid = false;
          var elemid = $(this).attr('id');
          var tp = $('<div class="tooltip bottom in validity-tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner ">'+validationmessage+'</div></div>').mouseover(function() { $(this).fadeOut() }).css(a).hide().appendTo("body").fadeIn();
         }
      });
    }

    return result.valid;
  }

  function validationMessage(element) {
  	return element.attr('data-msg') ? element.attr('data-msg') : 'This field is required';
  }

  $('.form-submit').click(function(e){
  	e.preventDefault();
  	var form = $(this).attr('data-validate') == 'form' ? $(this).parents('form') : $(this).attr('data-validate');
  	if(validateMyAjaxInputs(form)) {
  		if($(this).attr('data-validate') == 'form') {
				form.submit();
			} else {
				// for steps
				console.log('steps')
			}
  	}
  });
  setUtmParams();
});

    var choices = [];
    var arr = [];
    var suburbFull;
    var suburValue;
    var postValue;
    var submitReport;
    jQuery(document).ready(function($){
				var geoJson = '';
				if(typeof geodata !== 'undefined'){
					geoJson = geodata;
				}else{
					geoJson = 'https://asset.alternativemedia.com.au/geodata.json';
				}

        $.getJSON(geoJson, function( data ) {
          $.each(data, function(name, value) {
            value[2] = value[2].length < 4 ? '0' + value[2] : value[2];
              arr.push("" + value.join(', '));
          });
            $('#postcode, #work_postcode').autoComplete({
              minChars: 1,
              source: function(term, suggest) {
                  term = term.toLowerCase();
                  var choices = arr;

                  var matches = [];
                  for (var i = 0; i < choices.length; i++) {
                      if (~choices[i].toLowerCase().indexOf(term))
                          matches.push(choices[i]);
                  }

              suggest(matches);
	            },
	            onSelect: function(e, term, item) {
	                $(this).focus();
					var val = $('#postcode').val();
	                $('.autocomplete-suggestions').hide();
					localStorage.has_selected_address = 1;
					localStorage.selected_address = val;
	            },
	            cache: false
        });
    });

		$("#postcode, #work_postcode").keydown(function(event){
		    if(event.keyCode == 13) {
					var val = $(this).val();
					localStorage.has_selected_address = 1;
					localStorage.selected_address = val;
		    }
		});

		$("#postcode").change(function(){
			var val = $(this).val();
			if(typeof localStorage.selected_address !== 'undefined'){
				if(localStorage.selected_address == val){
					localStorage.has_selected_address = 1;
					localStorage.selected_address = val;
				}else{
					localStorage.has_selected_address = 0;
				}
			}
		});
});

function setUtmParams(){
	$('#utm_source').val(utm_source);
	$('#utm_medium').val(utm_medium);
	$('#utm_term').val(utmDatas.utm_term);
	$('#utm_content').val(utmDatas.utm_content);
	$('#utm_campaign').val(utmDatas.utm_campaign);

	$('#p_utm_source').val(utm_source);
	$('#p_utm_medium').val(utm_medium);
	$('#p_utm_term').val(utmDatas.utm_term);
	$('#p_utm_content').val(utmDatas.utm_content);
	$('#p_utm_campaign').val(utmDatas.utm_campaign);
}

$(document).ready(function(){
  	if (window.location.hash == "#movinghouse") {
    $('html, body').animate({
       scrollTop: $("#movinghouse").offset().top
     }, 1000);
    history.pushState('', document.title, window.location.pathname);
  }
});

jQuery(document).ready(function(){
    setUtmParamsToLinks();
});

  function appendUtmParams(href){
    if(typeof href === 'undefined'){
        href = window.location.href;
    }

    var utmparams = getCookie('utms');
    var innerutmparams = '';

    if(href !== undefined && href.indexOf('utm_') === -1 && href.indexOf('#') !== 0) {
        innerutmparams = utmparams;
        if(href.indexOf('?') !== -1) {
            innerutmparams = '&' + utmparams;
        }else{
            innerutmparams = '?' + utmparams;
        }
        href = href + innerutmparams;
        href.replace('?&','?');
    }
    return href;
  }

  function getUtmsArr(utms){
    if(typeof utms !== 'undefined') {
      var utmArr = utms.split('&');
      var result = [];
      for(var x = 0; x < utmArr.length; x++){
        var curr = utmArr[x].split('=');
        result[curr[0]] = curr[1];
      }

      return result;
    } else {
      var result = [];
      result['utm_source'] = 'direct';
      result['utm_medium'] = 'organic';
      result['utm_term'] = '';
      result['utm_content'] = '';
      result['utm_campaign'] = '';

      return result;
    }
  }

function setUtmParamsToLinks() {
    // manually add utm parameters on each links
    var utmparams = location.search;
    $('a').each(function() {
        var href = $(this).attr('href').replace(/\/+$/,'');
        if(href !== undefined && href.indexOf('utm_') === -1 && href.indexOf('#') !== 0) {
            href = href + utmparams;
            $(this).attr('href', href);
        }
    });
}
function deleteCookie(name) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
};

// Lazyload
var lazyLoadInstance = new LazyLoad({elements_selector: ".lazy"});
lazyLoadInstance.update();
